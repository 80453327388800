<template>
  <div class="card__information">
    <div class="card__information__item">
      <div class="icon" :class="[theme,size]">
        <slot></slot>
      </div>
      <p>{{ text }}</p>
    </div>
    <div class="span card__information__item">{{ value }}</div>
    <div class="card__information__item" v-if="action">
      <slot name="action"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
    text: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    size: {
      type: String,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_data_card_small.scss";
</style>
